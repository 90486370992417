import { useEffect, useState } from 'react';
import { createRoot } from "react-dom/client";

import "./style.scss";
import Style from "./Style";
import Forms from "./Forms";

// MailChimp Directory
document.addEventListener("DOMContentLoaded", () => {
  const allBlockDirectory = document.querySelectorAll(".wp-block-mcb-mailchimp");
  allBlockDirectory.forEach((directory) => {
    const attributes = JSON.parse(directory?.dataset?.attributes);
    // const dataInfo = directory?.dataset?.mcbinfo || "{}";

    // if (!key && !apiKey || !audienceId) {
    // 	return <span> </span>
    // }

    createRoot(directory).render(
      <>
        <RenderLayout attributes={attributes} />
      </>
    );

    directory?.removeAttribute("data-attributes");
  });
});


export const RenderLayout = ({ attributes }) => {
  const [credentials, setCredentials] = useState({});
  const { elements, content, config, theme, layout } = attributes;
  const { placement } = layout;
  const { apiKey, audienceId } = config;

  // useEffect(() => {
  //   if (dataInfo) {
  //     const data = scramble('decode', dataInfo);
  //     setCredentials(JSON.parse(data));
  //   }
  // }, [dataInfo]);

  // const { key, } = credentials || {};


  if (!audienceId) {
    return <span></span>;
  }

  return <>
    <Style attributes={attributes} clientId={attributes.cId} />

    <Forms attributes={attributes}>
      {elements.title && content.title.length > 0 && <h2 dangerouslySetInnerHTML={{ __html: content?.title }} />}

      {((theme !== 'form7' && placement !== "floating-top") && elements.desc && content.desc.length > 0) && <p dangerouslySetInnerHTML={{ __html: content?.desc }} />}

    </Forms></>

}

export const scramble = (task = "encode", data = "") => {

  const originalKey = "abcdefghijklmnopqrstuvwxyz1234567890";
  const key = "gteb2xnwloyz4h751icrp98vu63qmasfdj0k";
  let resultData = "";

  if (task == "decode") {
    if (data != "") {
      const length = data.length;
      for (let i = 0; i < length; i++) {
        const currentChar = data[i];
        const position = key.indexOf(currentChar);
        if (position !== -1) {
          resultData += originalKey[position];
        } else {
          resultData += currentChar;
        }
      }
    }
  }
  return resultData;
};